let iframe;

const init = () =>
  new Promise((resolve) => {
    if (iframe) {
      resolve();
    } else {
      iframe = document.getElementById('iframeMultisesion');
      iframe.setAttribute('src', process.env.NEXT_PUBLIC_MULTISESION_IFRAME);
      iframe.onload = () => {
        resolve();
      };
    }
  });

export async function setValueToIframe(message) {
  await init();
  iframe.contentWindow?.postMessage(
    {
      action: 'save',
      key: 'authorization_sso',
      value: message,
    },
    '*',
  );
}

export async function getValueFromIframe() {
  await init();
  iframe.contentWindow?.postMessage(
    {
      action: 'get',
      key: 'authorization_sso',
      value: '',
    },
    '*',
  );
}

export const getIframeValue = () =>
  new Promise((resolve) => {
    init();
    let timeout;
    let success;

    function messageHandler(event) {
      const { action, key, value } = event.data;
      if (action === 'returnData') {
        clearTimeout(timeout);
        console.debug(`VALORES DESDE IFRAME [KEY] : ${key} [VALUE] : ${value}`);
        success(value);
      }
    }

    success = (value) => {
      window.removeEventListener('message', messageHandler, false);
      resolve(value);
    };
    timeout = setTimeout(() => {
      console.debug('iframeMultisesion timeout');
      success('0');
    }, 3000);
    try {
      window.addEventListener('message', messageHandler, false);
      if (iframe.contentDocument) {
        iframe.onload = () => {
          getValueFromIframe();
        };
      } else {
        getValueFromIframe();
      }
    } catch (e) {
      success('0');
      console.error(e);
    }
  });
